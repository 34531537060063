import React, { Component } from 'react'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import StyledUL from "../components/styled-ul"

export default class Post extends Component {
  render() {
    const {
      data: {
        allWordpressPost,
      }
    } = this.props

    return (
      <Layout>
        <Header />
        <StyledUL posts={allWordpressPost.edges} />
      </Layout>
    )
  }
}

export const query = graphql`
  query CategoryQuery($slug: String!) {

    allWordpressPost(filter: {categories: {elemMatch: {slug: {eq: $slug}}}}) {
      totalCount
      edges {
        node {
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth:2800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          date(formatString: "MMMM DD, YYYY")
          datetime: date(formatString: "YYYY-MM-DD hh:mm:ss")
          slug
          content
        }
      }
    }
  }
`
